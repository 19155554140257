import request from '@http';

// 获取房源详情
export function getApartmentByUUID(uuid) {
    return request({
        method: 'GET',
        url: '/api/gzf/baseinfo/apartment/' + uuid,
    });
}

// 发布房源
export function publishApartment(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/publish/apartment',
        data,
    });
}

// 取消发布房源
export function cancelPublish(uuid) {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/cancel?uuid=' + uuid,
    });
}

// 获取所有房型信息
export function getPublishListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/list', // ?keyword&pageSize&page
        params,
    });
}

// 根据uuid获取发布房型
export function getByUuid(uuid) {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/get/' + uuid,
    });
}

// 根据房源uuid获取发布房型
export function getByApartmentUuid(uuid) {
    return request({
        method: 'GET',
        url: '/applet/rent/apartment/detail?uuid=' + uuid,
    });
}

// 新增房型
export function addPublishApi(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/publish/create',
        data,
    });
}

export function editPublishApi(uuid, data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/publish/update/' + uuid,
        data,
    });
}

// 获取小区列表
export function getCommunityListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/baseinfo/community/', // ?keyword&pageSize&page
        params,
    });
}

// 根据小区uuid获取房源列表
export function getApartmentByCommunity(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/not/apartment?communityUuid=' + params.uuid +
            "&keyword=" + (params.keyword ? params.keyword : "") +
            "&pageSize=" + params.pageSize +
            "&page=" + params.page, // ?keyword&pageSize&page&communityUuid
    });
}

// 获取房管信息
export function getUserListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/rent/user/list',
        params,
    });
}

// 关联房源
export function relationPublishApi(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/publish/relation',
        data,
    });
}

// 待发布、已发布列表
export function getApartmentByPublishStatus(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/tobe/apartment?' +
            "page=" + (params.page ? params.page : "") +
            "&pageSize=" + (params.pageSize ? params.pageSize : ""),
        params,
    });
}

// 模板关联的房源信息
export function getApartmentByPublishUuid(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/of/apartments?uuid=' + params.publishUuid +
            "&communityUuid=" + params.uuid +
            "&keyword=" + (params.keyword ? params.keyword : "") +
            "&page=" + (params.page ? params.page : "") +
            "&pageSize=" + (params.pageSize ? params.pageSize : ""),
    });
}

// 关联房源
export function publishDeleteApi(params) {
    return request({
        method: 'DELETE',
        url: '/api/gzf/publish/delete',
        params,
    });
}

// 2022/11/21 王江毅 查询全部房型
export function getPublishList() {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/list/nopage',
    });
}

// 2024-1-4-陈守亮-不明接口 获取付款方式
export function getPublishListapitow() {
    return request({
        method: 'GET',
        url: '/api/gzf/publish/deposit/code/list',
    });
}